import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const options = {
  renderNode: {
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
  },
}

class Page extends Component {
  render() {
    const { titre, body: {body}, contenu } = this.props.data.contentfulPage
    const path = this.props.location ? this.props.location.pathname : null;

    return (
      <Layout path={path}>
        <PageHeader title={titre} body={body} />
        <div id="blocks">
          {contenu.map((object, i) => (
            <div id="block">
              {i % 2 ? (
                <>
                  <ColumnImage data={object} />
                  <ColumnText data={object} />
                </>
              ) : (
                <>
                  <ColumnText data={object} />
                  <ColumnImage data={object} />
                </>
              )}
            </div>
          ))}
        </div>
      </Layout>
    )
  }
}

const ColumnText = ({ data }) => (
  <div id="column-text">
    <h3 dangerouslySetInnerHTML={{ __html: data.titre }} />
    <div className="content">{renderRichText(data.corps, options)}</div>
  </div>
)

const ColumnImage = ({ data }) => (
  <div id="column-img">
    <GatsbyImage image={getImage(data.image)} imgStyle={{ objectFit: "contain" }} alt={data.image.description} className="img"/>
  </div>
)

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      titre
      body {
        body
      }
      slug
      contenu {
        titre
        corps {
          raw
        }
        image { 
          description
          gatsbyImageData(
            layout: FULL_WIDTH
            quality:100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`
